.hero {
  padding-bottom: 100px;

  &__container {
    padding-top: calc(68px + 30px);
  }

  &__content {
    border-radius: 20px;
    background-color: #F8F8F8;
    padding: 40px 28px 40px 20px;
    overflow: hidden;
    position: relative;
  }

  &__left {
    max-width: 635px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 2;
  }

  &__button {
    width: fit-content;
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 50px;
    line-height: 0.915;
    color: #1c1c1c;

    span {
      color: #b0b0b0;
    }
  }

  &__descr {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.13;
    color: rgba(28, 28, 28, 0.8);
    max-width: 560px;
  }

  &__car {
    position: absolute;
    right: 28px;
    top: 35px;
  }

  &__line {
    position: absolute;
    z-index: 1;

    &--one {
      border: 1.75px solid rgba(28, 28, 28, 0.05);
      border-radius: 5241px;
      width: 952px;
      height: 952px;
      right: -313px;
      top: 23px;
      backface-visibility: hidden;
      animation: circle-four 3.1s infinite linear;
    }

    &--two {
      border: 1.63px solid rgba(28, 28, 28, 0.05);
      border-radius: 4897px;
      width: 889px;
      height: 889px;
      right: -282px;
      top: 55px;
      animation: circle-three 3.2s infinite linear;
    }

    &--three {
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
      right: -218px;
      top: 119px;
      animation: circle-two 3.3s infinite linear;
    }

    &--four {
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
      right: -193px;
      top: 143px;
      animation: circle-one 3.4s infinite linear;
    }
  }
}

@keyframes circle-one {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  11% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  55% {
    opacity: 1;
    transform: scale(1.1, 1.1);
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    transform: scale(1.15, 1.15);
  }
}

@keyframes circle-two {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  9% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  54% {
    opacity: 1;
    transform: scale(1.15, 1.15);
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }
}

@keyframes circle-three {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  6% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  52% {
    opacity: 1;
    transform: scale(1.2, 1.2);
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    transform: scale(1.25, 1.25);
  }
}

@keyframes circle-four {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }

  50% {
    opacity: 1;
    transform: scale(1.25, 1.25);
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}
