.preloader {
  position: relative;
  margin: 0 0 0 45%;
  height: 15px;
  visibility: hidden;
  opacity: 0;
  display: none;
  transition: 0.3s;
}

.preloader_active {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}

.preloader:before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: "";
  position: absolute;
  background: #efefef;
  animation: preloader_before 1.8s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.preloader:after {
  width: 12px;
  height: 12px;
  left: 15%;
  border-radius: 50%;
  content: "";
  position: absolute;
  background: #d3d3d3;
  animation: preloader_after 1.8s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes preloader_before {
  0% {
    transform: translateX(0px) rotate(0deg);
  }

  50% {
    transform: translateX(160px) scale(1.5) rotate(264deg);
    background: rgb(1, 133, 199);
    border-radius: 0;
  }

  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes preloader_after {
  0% {
    transform: translateX(0px) rotate(0deg);
  }

  50% {
    transform: translateX(-160px) scale(1.5) rotate(-264deg);
    background: var(--white-color);
    border-radius: 0;
  }

  100% {
    transform: translateX(0px);
  }
}
