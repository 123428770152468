.header {
  position: fixed;
  left: 0;
  top: 10px;
  width: 100%;
  z-index: 9000;

  &__content {
    background: #1c1c1c;
    border-radius: 20px;
    padding: 12px 20px;
  }
}
