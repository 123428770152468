.contact-form {

  &__title {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 50px;
    line-height: 0.91;
    color: #fff;
    margin-bottom: 23px;
  }

  &__subtitle {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.13;
    color: rgba(255, 255, 255, 0.8);
    max-width: 450px;
    margin-bottom: 23px;
  }

  &__input {
    border: none;
    border-radius: 10px;
    width: 100%;
    background: var(--white-color);
    padding: 28px 20px;
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    color: #1c1c1c;
    margin-bottom: 10px;

    &::placeholder {
      font-family: inherit;
      color: rgba(#1c1c1c, 0.4);
      font-weight: inherit;
      font-size: inherit;
    }
  }

  &__message {
    text-align: center;
  }

  &__policy {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__link {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 137%;
    color: #fff;
    text-decoration: underline;
  }
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  display: flex;
  gap: 20px;
  align-items: center;
  user-select: none;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: #fff;
}

.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #fff;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked+label::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
