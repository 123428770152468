.contact {
  padding-bottom: 100px;

  &__content {
    display: flex;
    gap: 12px;
  }

  &__img {
    border-radius: 10px;
  }

  &__form {
    border-radius: 10px;
    background: var(--accent-color);
    max-width: 574px;
    max-height: 694px;
    position: relative;
    overflow: hidden;

    &--content {
      padding: 40px 20px;
      position: relative;
      z-index: 2;
    }
  }

  &__line {
    position: absolute;
    z-index: 1;

    &--one {
      top: -550px;
      left: 62px;
      border: 1.31px solid rgba(255, 255, 255, 0.2);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--two {
      left: 37px;
      top: -576px;
      border: 1.40px solid rgba(255, 255, 255, 0.2);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--three {
      left: 342px;
      top: 222px;
      border: 1.31px solid rgba(255, 255, 255, 0.2);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--four {
      left: 317px;
      top: 197px;
      border: 1.40px solid rgba(255, 255, 255, 0.2);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }
  }
}
