.mobile div {
  display: block;
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #3f8fd3;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  z-index: 9999999;
  border: 4px solid #9ad1ff;
  cursor: pointer;
  box-sizing: content-box;
}

.mobile div.active {
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.mobile div span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 2.7px;
  background-color: #fff;
  border-radius: 13px;
  transition-duration: .4s;
}

.mobile div span:nth-child(1) {
  top: 12px;
}

.mobile div span:nth-child(2) {
  top: 20px;
}

.mobile div span:nth-child(3) {
  bottom: 11px;
}

.mobile div.active span:nth-child(1) {
  transform: translate(-12.5px, 9px) rotate(-45deg);
  background-color: #000;
  width: 25px;
}

.mobile div.active span:nth-child(2) {
  transition-duration: 0s;
  opacity: 0;
}

.mobile div.active span:nth-child(3) {
  transform: translate(-12.5px, -7px) rotate(45deg);
  background-color: #000;
  width: 25px;
}

.mobile nav {
  display: block;
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999990;
  transition-duration: .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mobile nav.open {
  background-color: var(--accent-color);
  width: 100%;
  height: 100%;
  right: 0px;
  bottom: 0px;
  border-radius: 0;
}

.mobile nav ul {
  display: none;
}

.mobile nav ul.show {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 2;
}

.mobile ul li:not(:last-child) {
  margin-bottom: 22px;
}

.mobile ul li a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

@keyframes fadein {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;
  }
}

.mobile {

  &__nav {
    overflow: hidden;
    position: relative;
  }

  &__line {
    position: absolute;
    z-index: 1;

    &--one {
      left: 277px;
      top: 67px;
      border: 1.31px solid rgba(255, 255, 255, 0.18);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--two {
      left: 252px;
      top: 42px;
      border: 1.40px solid rgba(255, 255, 255, 0.18);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }
  }

  &__btn {
    position: relative;
    z-index: 2;
  }
}
