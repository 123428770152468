.social {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 115px;

  &__item {
    &:not(:last-child) {
      border-right: 1px solid #b0b0b0;
    }

    padding: 0 10px;
  }

  &__link {
    display: flex;

    svg {
      height: 22px;
      transform: rotate(0deg);
      transition: .5s transform ease-in-out;
    }

    &:hover {
      svg {
        transform: rotate(360deg);
      }
    }
  }

  &__block {
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    max-width: 228px;
    position: absolute;
    border-radius: 10px;
    background: var(--accent-color);
    padding: 22px 20px;
    transition: .3s opacity ease-in-out, .3s visibility ease-in-out;

    &--phone {
      bottom: -100%;
      left: 50%;
      transform: translate(-50%, 65%);
    }

    &--youtube {
      bottom: -100%;
      left: 50%;
      transform: translate(-50%, 70%);
    }
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.01em;
    color: var(--white-color);
  }

  &__number {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.01em;
    color: rgba(#fff, 0.8);
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.01em;
    color: rgba(#fff, 0.8);
    text-decoration: underline;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}
