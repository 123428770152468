.video {
  padding-bottom: 80px;

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__img {
    border-radius: 8px;
  }

  &__slide {
    max-width: 281px;

    &--white {
      border-radius: 10px;
      background: #f8f8f8;
    }

    &--accent {
      background: var(--accent-color);
      border-radius: 10px;
    }
  }

  &__content {
    padding: 10px;
    position: relative;

    &--more {
      flex-direction: column;
      padding: 20px 20px 30px 20px;
      min-height: inherit;
      justify-content: space-between;
      display: flex;
    }
  }

  &__about {
    background: #f8f8f8;
    border-radius: 10px;
    max-width: 281px;
    min-height: 337px;
    position: relative;
    overflow: hidden;
  }

  &__watch {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 137%;
    color: #1c1c1c;
  }

  &__more {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: fit-content;
  }
}
