.btn {

  &--phone {
    display: flex;
  }

  &--youtube {
    display: flex;
  }

  &--contact {
    border-radius: 30px;
    background: var(--white-color);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 155%;
    letter-spacing: -0.01em;
    text-align: center;
    color: #000;
    padding: 8px 15px;
    transition: .3s color ease-in-out, .3s background ease-in-out;

    &:hover {
      color: var(--white-color);
      background: var(--accent-color);
    }
  }

  &--mobile {
    border-radius: 30px;
    background: var(--white-color);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 155%;
    letter-spacing: -0.01em;
    text-align: center;
    color: #000;
    padding: 8px 15px;
    transition: .3s color ease-in-out, .3s background ease-in-out;

    &:hover {
      color: var(--white-color);
      background: var(--black-color);
    }
  }

  &--car {
    background: var(--accent-color);
    border-radius: 1000px;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 112%;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 6px 6px 6px 15px;
    transition: .3s background ease-in-out;

    svg {
      transform: rotate(0deg);
      transition: .4s transform ease-in-out;
    }

    &:hover {
      background: rgba(#0af, .8);

      svg {
        transform: rotate(360deg);
      }
    }
  }

  &--watch {
    background: var(--accent-color);
    border-radius: 1000px;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 112%;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 6px 6px 6px 15px;
    transition: .3s background ease-in-out;

    svg {
      transform: rotate(0deg);
      transition: .4s transform ease-in-out;
    }

    &:hover {
      background: rgba(#0af, .8);

      svg {
        transform: rotate(360deg);
      }
    }
  }

  &--pick {
    background: var(--accent-color);
    border-radius: 1000px;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 112%;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 6px 6px 6px 15px;
    transition: .3s background ease-in-out;

    svg {
      transform: rotate(0deg);
      transition: .4s transform ease-in-out;
    }

    &:hover {
      background: rgba(#0af, .8);

      svg {
        transform: rotate(360deg);
      }
    }
  }

  &--submit {
    border-radius: 1000px;
    background: #1c1c1c;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 112%;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 6px 6px 6px 15px;
    transition: .3s background ease-in-out, .3s opacity ease-in-out;

    svg {
      transform: rotate(0deg);
      transition: .4s transform ease-in-out;
    }

    &:hover {
      background: rgba(rgb(12, 12, 12), .8);

      svg {
        transform: rotate(360deg);
      }
    }
  }
}

button:disabled {
  opacity: 0.6;
}
