.advantages {
  padding-bottom: 100px;

  &__cards {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  }
}
