.card {
  border-radius: 10px;
  max-width: 281px;
  min-height: 281px;
  position: relative;
  overflow: hidden;

  &--silver {
    background: #f8f8f8;
  }

  &--accent {
    background: var(--accent-color);
  }

  &__content {
    padding: 20px 20px 25px 20px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: inherit;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  &__icon {
    width: 45px;
    height: 45px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    &--black {
      background: #1c1c1c;
    }

    &--white {
      background: var(--white-color);
    }
  }

  &__text {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 15.4px;
    line-height: 137%;

    &--black {
      color: rgba(28, 28, 28, 0.8);
    }

    &--white {
      color: rgba(#fff, 0.8);
    }
  }

  &__line {
    position: absolute;
    z-index: 1;

    &--one {
      left: 100px;
      bottom: 100px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--two {
      left: 125px;
      bottom: 125px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--three {
      left: 207px;
      bottom: -185px;
      border: 1.40px solid rgba(255, 255, 255, 0.18);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--four {
      left: 232px;
      bottom: -160px;
      border: 1.31px solid rgba(255, 255, 255, 0.18);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--five {
      left: -148px;
      top: 46px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--six {
      left: -123px;
      top: 72px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--seven {
      top: 101px;
      left: -277px;
      border: 1.40px solid rgba(255, 255, 255, 0.18);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--eight {
      left: -252px;
      top: 127px;
      border: 1.31px solid rgba(255, 255, 255, 0.18);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }
  }
}
