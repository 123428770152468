.policy {
  padding-top: calc(68px + 30px);
  padding-bottom: 30px;
  color: #1c1c1c;

  &__title {
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__text {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: 20px;
  }
}
