html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  overflow-x: clip;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: none;
}

.section {

  &__title {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 50px;
    line-height: 100%;
    margin-left: 20px;
    margin-bottom: 30px;

    &--black {
      color: #1c1c1c;
    }

    &--white {
      color: var(--white-color);
    }

    span {
      color: #b0b0b0;
    }
  }
}

.main {
  &__bg {
    background-color: var(--accent-color);
    border-radius: 10px;
    margin-bottom: 58px !important;
  }
}

body {
  &::-webkit-scrollbar {
    width: 6px;
    background: #bdbdbd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: var(--accent-color);
    transition: .7s background;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(3, 154, 230);
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

* {
  cursor: none
}

#aura,
#cursor {
  border-radius: 100%;
  pointer-events: none;
  position: absolute;
  -webkit-transition: opacity .2s cubic-bezier(.75, -1.27, .3, 2.33), -webkit-transform .5s cubic-bezier(.75, -1.27, .3, 2.33);
  transition: opacity .2s cubic-bezier(.75, -1.27, .3, 2.33), -webkit-transform .5s cubic-bezier(.75, -1.27, .3, 2.33);
  transition: transform .5s cubic-bezier(.75, -1.27, .3, 2.33), opacity .2s cubic-bezier(.75, -1.27, .3, 2.33);
  transition: transform .5s cubic-bezier(.75, -1.27, .3, 2.33), opacity .2s cubic-bezier(.75, -1.27, .3, 2.33), -webkit-transform .5s cubic-bezier(.75, -1.27, .3, 2.33);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10000
}

#cursor {
  background-color: rgba(#088fd3, .90);
  height: 8px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 8px
}

#cursor.active {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0)
}

#aura {
  background-color: rgba(#088fd3, .45);
  border: 1px solid rgba(#088fd3, .40);
  height: 46px;
  -webkit-transform: translate(5px, 5px);
  -ms-transform: translate(5px, 5px);
  transform: translate(5px, 5px);
  width: 46px
}

#aura.active {
  border: 1.2px solid rgba(#088fd3, .33);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3)
}

#aura.hidden,
#cursor.hidden {
  -webkit-transform: scale(.001);
  -ms-transform: scale(.001);
  transform: scale(.001)
}
