:root {
  --font-family: "Inter Tight", sans-serif;
  --second-family: "Manrope", sans-serif;
  --third-family: "Inter", sans-serif;
  --content-width: 1160px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  --white-color: #fff;
  --black-color: #000;
  --accent-color: #0af;
}
