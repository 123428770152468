.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 20px;
    }

    position: relative;

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0;
      height: 2px;
      background-color: rgba(#fff, .6);
      transition: .3s width cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &--no {
      &:hover {
        &::after {
          width: 0;
        }
      }
    }
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 144%;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.6);
  }
}
