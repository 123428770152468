.job {
  padding-bottom: 50px;

  &__blocks {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__info {
    border-radius: 10px;
    background: #f8f8f8;
    position: relative;
    overflow: hidden;
  }

  &__content {
    padding: 40px 20px;
    position: relative;
    z-index: 2;
  }

  &__video {
    min-width: 574px;
    min-height: 707px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;

    video {
      border-radius: 10px;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0;
      object-fit: cover;
    }
  }

  &__item {
    border-radius: 10px;
    background: var(--white-color);
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__text {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 15.4px;
    line-height: 1.2;
    color: #1c1c1c;
    max-width: 476px;

    span {
      color: rgba(28, 28, 28, 0.8);
    }
  }

  &__line {
    position: absolute;
    z-index: 1;

    &--one {
      left: 381px;
      top: 5px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 674px;
      height: 674px;
    }

    &--two {
      top: 27px;
      left: 403px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 629px;
      height: 629px;
    }
  }

  &__button {
    width: fit-content;
    margin-top: 30px;
  }
}
