:root {
  --font-family: "Inter Tight", sans-serif;
  --second-family: "Manrope", sans-serif;
  --third-family: "Inter", sans-serif;
  --content-width: 1160px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white-color: #fff;
  --black-color: #000;
  --accent-color: #0af;
}

@media (max-width: 420px) {
  .reviews .reviews__info {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
}
@media (max-width: 500px) {
  .hero .hero__container .hero__title {
    font-size: clamp(1.5rem, 3.2vw, 3vh);
  }
  .hero .hero__container .hero__descr {
    font-size: clamp(0.8rem, 0.5vw, 5vh);
  }
  .hero .btn--car {
    font-size: 13px;
    padding: 4px 4px 4px 12px;
    gap: 10px;
  }
  .hero .hero__left {
    gap: 20px;
  }
  .hero .hero__content {
    padding: 25px 20px 0 20px;
  }
  .hero .hero__container {
    padding-top: 86px;
  }
  .section .container .section__title {
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 25px;
  }
  .card .card__text {
    font-size: 14px;
  }
  .cards .card {
    min-height: 230px;
  }
  .card .card__icon {
    width: 40px;
    height: 40px;
  }
  .reviews .reviews__pictures {
    width: 100%;
    padding: 0;
  }
  .reviews .reviews__img {
    width: 95%;
    height: 200px;
    margin: 0 auto;
    padding: 10px 0;
  }
  .section .reviews__info .section__title {
    font-size: 27px;
    margin: 0;
  }
  .reviews .reviews__text {
    font-size: 15px;
  }
  .section .job__content .section__title {
    font-size: 27px;
    margin: 0 0 15px 0;
    text-align: center;
  }
  .job .job__item {
    padding: 15px;
  }
  .job .job__text {
    max-width: 100%;
    font-size: 14px;
  }
  .job .job__content {
    padding: 25px 10px;
  }
  .job .btn--pick {
    font-size: 13px;
    padding: 4px 4px 4px 12px;
    gap: 10px;
  }
  .contact .contact__container .contact__img {
    width: 100% !important;
  }
  .contact-form .contact-form__title {
    font-size: clamp(1.9rem, 3.2vw, 3vh);
  }
  .contact-form .contact-form--content {
    padding: 30px 14px;
  }
  .contact-form .contact-form__subtitle {
    font-size: 14px;
  }
  .contact-form .contact-form__input {
    font-size: 14px;
    padding: 19px 12px;
  }
  .contact-form .custom-checkbox + label {
    font-size: 14px;
    gap: 8px;
  }
  .contact-form .contact-form__link {
    font-size: 14px;
  }
  .contact-form .custom-checkbox + label::before {
    width: 25px;
    height: 25px;
  }
  .contact-form .btn--submit {
    font-size: 13px;
    padding: 4px 4px 4px 12px;
    gap: 10px;
  }
  .video .video__container .video__slide .video__about {
    min-height: 450px;
  }
  #policy {
    padding-top: 100px !important;
  }
  .policy .policy__container .policy__title {
    font-size: 20px;
  }
  .policy .policy__subtitle {
    font-size: 16px;
  }
  .policy .policy__text {
    font-size: 14px;
  }
  .reviews .reviews__about {
    height: 519px;
  }
  .reviews .reviews__content {
    height: 520px;
    max-height: 520px;
  }
  .reviews .reviews__content--more {
    justify-content: space-around !important;
  }
}
@media (max-width: 768px) {
  #policy {
    padding-top: 130px;
  }
  .policy .policy__title {
    font-size: 25px;
  }
  .reviews .reviews__about {
    max-width: 100%;
  }
  .reviews .reviews__content--more {
    flex-direction: column !important;
  }
  .reviews .reviews__car--img {
    width: 83%;
    margin: 0 auto;
  }
  .section .section__title {
    font-size: 35px;
  }
  .cards .card {
    max-width: 100%;
    width: 100%;
  }
  .reviews .reviews__content {
    flex-direction: column-reverse;
  }
  .reviews .reviews__information {
    max-width: 100%;
    min-height: 240px;
  }
  .job .job__video {
    min-height: 450px;
  }
  .job .job__item svg {
    display: none;
  }
  .contact .contact__container .contact__img {
    width: 80%;
  }
  .job .job__video {
    display: none;
  }
  .video .video__info {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  .video h2 {
    margin-bottom: 0;
  }
  .footer .footer__left {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .footer .footer__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
@media (min-width: 968px) {
  .mobile {
    display: none;
  }
}
@media (max-width: 968px) {
  .header__content {
    display: none;
  }
  #cursor,
  #aura {
    display: none;
  }
  .hero .hero__car {
    position: relative;
    right: 0;
    top: 0;
  }
  .hero .hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hero .hero__title {
    max-width: 100%;
    text-align: center;
  }
  .hero .hero__descr {
    max-width: 100% !important;
    text-align: center;
  }
  .hero .hero__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #hero {
    padding-bottom: 50px;
  }
  #advantages {
    padding-bottom: 50px;
  }
  #reviews {
    padding-bottom: 50px;
  }
  #contact {
    padding-bottom: 50px;
  }
  #video {
    padding-bottom: 50px;
  }
  .video .video__slide .video__about {
    min-height: 494px;
  }
  .footer .footer__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
@media (max-width: 1024px) {
  .header .social {
    margin-left: 0;
  }
  .video .video__img {
    width: 100%;
  }
  .video .video__about {
    max-width: 100%;
    min-height: 394px;
  }
  .video .video__car--img {
    width: 100%;
  }
}
@media (max-width: 1170px) {
  .hero .hero__title {
    font-size: clamp(2rem, 4.2vw, 5vh);
    max-width: 600px;
  }
  .hero .hero__descr {
    font-size: clamp(0.9rem, 0.5vw, 5vh);
    max-width: 400px;
  }
  .reviews .reviews__content {
    justify-content: center;
  }
  .job .job__blocks {
    flex-direction: column;
  }
  .job .job__video video {
    left: 0;
  }
  .contact .contact__content {
    flex-direction: column;
  }
  .contact .contact__img {
    height: 500px;
    margin: 0 auto;
  }
  .contact .contact__form {
    margin: 0 auto;
  }
  .video .video__slide {
    max-width: 100%;
  }
  .video .video__img {
    margin: 0 auto;
  }
}
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Manrope";
  src: url("../fonts/../fonts/Manrope-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/../fonts/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter Tight";
  src: url("../fonts/../fonts/InterTight-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter Tight";
  src: url("../fonts/../fonts/InterTight-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter Tight";
  src: url("../fonts/../fonts/InterTight-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  overflow-x: clip;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: none;
}

.section__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 50px;
  line-height: 100%;
  margin-left: 20px;
  margin-bottom: 30px;
}
.section__title--black {
  color: #1c1c1c;
}
.section__title--white {
  color: var(--white-color);
}
.section__title span {
  color: #b0b0b0;
}

.main__bg {
  background-color: var(--accent-color);
  border-radius: 10px;
  margin-bottom: 58px !important;
}

body::-webkit-scrollbar {
  width: 6px;
  background: #bdbdbd;
}
body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: var(--accent-color);
  transition: 0.7s background;
}
body::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 154, 230);
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

* {
  cursor: none;
}

#aura,
#cursor {
  border-radius: 100%;
  pointer-events: none;
  position: absolute;
  -webkit-transition: opacity 0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33), -webkit-transform 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33);
  transition: opacity 0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33), -webkit-transform 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33);
  transition: transform 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33), opacity 0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33);
  transition: transform 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33), opacity 0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33), -webkit-transform 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10000;
}

#cursor {
  background-color: rgba(8, 143, 211, 0.9);
  height: 8px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 8px;
}

#cursor.active {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

#aura {
  background-color: rgba(8, 143, 211, 0.45);
  border: 1px solid rgba(8, 143, 211, 0.4);
  height: 46px;
  -webkit-transform: translate(5px, 5px);
  -ms-transform: translate(5px, 5px);
  transform: translate(5px, 5px);
  width: 46px;
}

#aura.active {
  border: 1.2px solid rgba(8, 143, 211, 0.33);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

#aura.hidden,
#cursor.hidden {
  -webkit-transform: scale(0.001);
  -ms-transform: scale(0.001);
  transform: scale(0.001);
}

.header {
  position: fixed;
  left: 0;
  top: 10px;
  width: 100%;
  z-index: 9000;
}
.header__content {
  background: #1c1c1c;
  border-radius: 20px;
  padding: 12px 20px;
}

.hero {
  padding-bottom: 100px;
}
.hero__container {
  padding-top: 98px;
}
.hero__content {
  border-radius: 20px;
  background-color: #F8F8F8;
  padding: 40px 28px 40px 20px;
  overflow: hidden;
  position: relative;
}
.hero__left {
  max-width: 635px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 2;
}
.hero__button {
  width: fit-content;
}
.hero__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 50px;
  line-height: 0.915;
  color: #1c1c1c;
}
.hero__title span {
  color: #b0b0b0;
}
.hero__descr {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.13;
  color: rgba(28, 28, 28, 0.8);
  max-width: 560px;
}
.hero__car {
  position: absolute;
  right: 28px;
  top: 35px;
}
.hero__line {
  position: absolute;
  z-index: 1;
}
.hero__line--one {
  border: 1.75px solid rgba(28, 28, 28, 0.05);
  border-radius: 5241px;
  width: 952px;
  height: 952px;
  right: -313px;
  top: 23px;
  backface-visibility: hidden;
  animation: circle-four 3.1s infinite linear;
}
.hero__line--two {
  border: 1.63px solid rgba(28, 28, 28, 0.05);
  border-radius: 4897px;
  width: 889px;
  height: 889px;
  right: -282px;
  top: 55px;
  animation: circle-three 3.2s infinite linear;
}
.hero__line--three {
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
  right: -218px;
  top: 119px;
  animation: circle-two 3.3s infinite linear;
}
.hero__line--four {
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
  right: -193px;
  top: 143px;
  animation: circle-one 3.4s infinite linear;
}

@keyframes circle-one {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  11% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  55% {
    opacity: 1;
    transform: scale(1.1, 1.1);
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
    transform: scale(1.15, 1.15);
  }
}
@keyframes circle-two {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  9% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  54% {
    opacity: 1;
    transform: scale(1.15, 1.15);
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }
}
@keyframes circle-three {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  6% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  52% {
    opacity: 1;
    transform: scale(1.2, 1.2);
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
    transform: scale(1.25, 1.25);
  }
}
@keyframes circle-four {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  50% {
    opacity: 1;
    transform: scale(1.25, 1.25);
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}
.advantages {
  padding-bottom: 100px;
}
.advantages__cards {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

.reviews {
  padding-bottom: 100px;
}
.reviews__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-top: 30px;
}
.reviews__buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
.reviews__button {
  width: fit-content;
}
.reviews__img {
  border-radius: 8px;
}
.reviews__content {
  display: flex;
  align-items: center;
  gap: 12px;
}
.reviews__content--more {
  flex-direction: column;
  padding: 20px 14px 32px 22px;
  min-height: inherit;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.reviews__about {
  background: #f8f8f8;
  border-radius: 10px;
  max-width: 281px;
  min-height: 337px;
  position: relative;
  overflow: hidden;
}
.reviews__watch {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: #1c1c1c;
}
.reviews__more {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.reviews__information {
  background: #f8f8f8;
  border-radius: 10px;
  max-width: 281px;
  position: relative;
  overflow: hidden;
  min-height: 387px;
}
.reviews__information--content {
  padding: 20px;
}
.reviews__text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: #1c1c1c;
  position: relative;
  z-index: 2;
}
.reviews__pictures {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
}
.reviews__line {
  position: absolute;
  z-index: 1;
}
.reviews__line--one {
  left: -108px;
  bottom: 50px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 916px;
  height: 916px;
  transform: rotate(54deg);
}
.reviews__line--two {
  left: -88px;
  bottom: 90px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 856px;
  height: 856px;
}
.reviews__line--three {
  left: 240px;
  top: -184px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.reviews__line--four {
  left: 265px;
  top: -165px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.reviews__line--five {
  top: 72px;
  left: -123px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.reviews__line--six {
  top: 47px;
  left: -148px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.reviews__line--seven {
  top: 242px;
  right: 36px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.reviews__line--eight {
  top: 266px;
  right: 62px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.reviews__line--nine {
  top: 177px;
  right: -178px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.reviews__line--ten {
  top: 152px;
  right: -203px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}

.job {
  padding-bottom: 50px;
}
.job__blocks {
  display: flex;
  align-items: center;
  gap: 12px;
}
.job__info {
  border-radius: 10px;
  background: #f8f8f8;
  position: relative;
  overflow: hidden;
}
.job__content {
  padding: 40px 20px;
  position: relative;
  z-index: 2;
}
.job__video {
  min-width: 574px;
  min-height: 707px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.job__video video {
  border-radius: 10px;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  object-fit: cover;
}
.job__item {
  border-radius: 10px;
  background: var(--white-color);
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.job__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.job__text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 15.4px;
  line-height: 1.2;
  color: #1c1c1c;
  max-width: 476px;
}
.job__text span {
  color: rgba(28, 28, 28, 0.8);
}
.job__line {
  position: absolute;
  z-index: 1;
}
.job__line--one {
  left: 381px;
  top: 5px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 674px;
  height: 674px;
}
.job__line--two {
  top: 27px;
  left: 403px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 629px;
  height: 629px;
}
.job__button {
  width: fit-content;
  margin-top: 30px;
}

.contact {
  padding-bottom: 100px;
}
.contact__content {
  display: flex;
  gap: 12px;
}
.contact__img {
  border-radius: 10px;
}
.contact__form {
  border-radius: 10px;
  background: var(--accent-color);
  max-width: 574px;
  max-height: 694px;
  position: relative;
  overflow: hidden;
}
.contact__form--content {
  padding: 40px 20px;
  position: relative;
  z-index: 2;
}
.contact__line {
  position: absolute;
  z-index: 1;
}
.contact__line--one {
  top: -550px;
  left: 62px;
  border: 1.31px solid rgba(255, 255, 255, 0.2);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.contact__line--two {
  left: 37px;
  top: -576px;
  border: 1.4px solid rgba(255, 255, 255, 0.2);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.contact__line--three {
  left: 342px;
  top: 222px;
  border: 1.31px solid rgba(255, 255, 255, 0.2);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.contact__line--four {
  left: 317px;
  top: 197px;
  border: 1.4px solid rgba(255, 255, 255, 0.2);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}

.video {
  padding-bottom: 80px;
}
.video__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.video__buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.video__img {
  border-radius: 8px;
}
.video__slide {
  max-width: 281px;
}
.video__slide--white {
  border-radius: 10px;
  background: #f8f8f8;
}
.video__slide--accent {
  background: var(--accent-color);
  border-radius: 10px;
}
.video__content {
  padding: 10px;
  position: relative;
}
.video__content--more {
  flex-direction: column;
  padding: 20px 20px 30px 20px;
  min-height: inherit;
  justify-content: space-between;
  display: flex;
}
.video__about {
  background: #f8f8f8;
  border-radius: 10px;
  max-width: 281px;
  min-height: 337px;
  position: relative;
  overflow: hidden;
}
.video__watch {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: #1c1c1c;
}
.video__more {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video__button {
  width: fit-content;
}

.footer {
  padding-bottom: 10px;
}
.footer__content {
  border-radius: 20px;
  background: #1c1c1c;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer__left {
  display: flex;
  gap: 30px;
}
.footer__name {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);
}
.footer__link {
  font-family: var(--font-family);
  font-weight: 400;
  text-decoration: underline;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s color ease-in-out;
}
.footer__link:hover {
  color: #fff;
}
.footer__item {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);
}

.policy {
  padding-top: 98px;
  padding-bottom: 30px;
  color: #1c1c1c;
}
.policy__title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.policy__subtitle {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}
.policy__text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav__list {
  display: flex;
  align-items: center;
}
.nav__item {
  position: relative;
}
.nav__item:not(:last-child) {
  margin-right: 20px;
}
.nav__item:hover::after {
  width: 100%;
}
.nav__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: 0.3s width cubic-bezier(0.39, 0.575, 0.565, 1);
}
.nav__item--no:hover::after {
  width: 0;
}
.nav__link {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 144%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);
}

.btn--phone {
  display: flex;
}
.btn--youtube {
  display: flex;
}
.btn--contact {
  border-radius: 30px;
  background: var(--white-color);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 155%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000;
  padding: 8px 15px;
  transition: 0.3s color ease-in-out, 0.3s background ease-in-out;
}
.btn--contact:hover {
  color: var(--white-color);
  background: var(--accent-color);
}
.btn--mobile {
  border-radius: 30px;
  background: var(--white-color);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000;
  padding: 8px 15px;
  transition: 0.3s color ease-in-out, 0.3s background ease-in-out;
}
.btn--mobile:hover {
  color: var(--white-color);
  background: var(--black-color);
}
.btn--car {
  background: var(--accent-color);
  border-radius: 1000px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 112%;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 6px 6px 15px;
  transition: 0.3s background ease-in-out;
}
.btn--car svg {
  transform: rotate(0deg);
  transition: 0.4s transform ease-in-out;
}
.btn--car:hover {
  background: rgba(0, 170, 255, 0.8);
}
.btn--car:hover svg {
  transform: rotate(360deg);
}
.btn--watch {
  background: var(--accent-color);
  border-radius: 1000px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 112%;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 6px 6px 15px;
  transition: 0.3s background ease-in-out;
}
.btn--watch svg {
  transform: rotate(0deg);
  transition: 0.4s transform ease-in-out;
}
.btn--watch:hover {
  background: rgba(0, 170, 255, 0.8);
}
.btn--watch:hover svg {
  transform: rotate(360deg);
}
.btn--pick {
  background: var(--accent-color);
  border-radius: 1000px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 112%;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 6px 6px 15px;
  transition: 0.3s background ease-in-out;
}
.btn--pick svg {
  transform: rotate(0deg);
  transition: 0.4s transform ease-in-out;
}
.btn--pick:hover {
  background: rgba(0, 170, 255, 0.8);
}
.btn--pick:hover svg {
  transform: rotate(360deg);
}
.btn--submit {
  border-radius: 1000px;
  background: #1c1c1c;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 112%;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 6px 6px 15px;
  transition: 0.3s background ease-in-out, 0.3s opacity ease-in-out;
}
.btn--submit svg {
  transform: rotate(0deg);
  transition: 0.4s transform ease-in-out;
}
.btn--submit:hover {
  background: rgba(12, 12, 12, 0.8);
}
.btn--submit:hover svg {
  transform: rotate(360deg);
}

button:disabled {
  opacity: 0.6;
}

.social {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 115px;
}
.social__item {
  padding: 0 10px;
}
.social__item:not(:last-child) {
  border-right: 1px solid #b0b0b0;
}
.social__link {
  display: flex;
}
.social__link svg {
  height: 22px;
  transform: rotate(0deg);
  transition: 0.5s transform ease-in-out;
}
.social__link:hover svg {
  transform: rotate(360deg);
}
.social__block {
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  max-width: 228px;
  position: absolute;
  border-radius: 10px;
  background: var(--accent-color);
  padding: 22px 20px;
  transition: 0.3s opacity ease-in-out, 0.3s visibility ease-in-out;
}
.social__block--phone {
  bottom: -100%;
  left: 50%;
  transform: translate(-50%, 65%);
}
.social__block--youtube {
  bottom: -100%;
  left: 50%;
  transform: translate(-50%, 70%);
}
.social__name {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: var(--white-color);
}
.social__number {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.8);
}
.social__link {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
}
.social__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card {
  border-radius: 10px;
  max-width: 281px;
  min-height: 281px;
  position: relative;
  overflow: hidden;
}
.card--silver {
  background: #f8f8f8;
}
.card--accent {
  background: var(--accent-color);
}
.card__content {
  padding: 20px 20px 25px 20px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: inherit;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.card__icon {
  width: 45px;
  height: 45px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.card__icon--black {
  background: #1c1c1c;
}
.card__icon--white {
  background: var(--white-color);
}
.card__text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 15.4px;
  line-height: 137%;
}
.card__text--black {
  color: rgba(28, 28, 28, 0.8);
}
.card__text--white {
  color: rgba(255, 255, 255, 0.8);
}
.card__line {
  position: absolute;
  z-index: 1;
}
.card__line--one {
  left: 100px;
  bottom: 100px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.card__line--two {
  left: 125px;
  bottom: 125px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.card__line--three {
  left: 207px;
  bottom: -185px;
  border: 1.4px solid rgba(255, 255, 255, 0.18);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.card__line--four {
  left: 232px;
  bottom: -160px;
  border: 1.31px solid rgba(255, 255, 255, 0.18);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.card__line--five {
  left: -148px;
  top: 46px;
  border: 1.4px solid rgba(28, 28, 28, 0.05);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.card__line--six {
  left: -123px;
  top: 72px;
  border: 1.31px solid rgba(28, 28, 28, 0.05);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.card__line--seven {
  top: 101px;
  left: -277px;
  border: 1.4px solid rgba(255, 255, 255, 0.18);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.card__line--eight {
  left: -252px;
  top: 127px;
  border: 1.31px solid rgba(255, 255, 255, 0.18);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}

.preloader {
  position: relative;
  margin: 0 0 0 45%;
  height: 15px;
  visibility: hidden;
  opacity: 0;
  display: none;
  transition: 0.3s;
}

.preloader_active {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}

.preloader:before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: "";
  position: absolute;
  background: #efefef;
  animation: preloader_before 1.8s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.preloader:after {
  width: 12px;
  height: 12px;
  left: 15%;
  border-radius: 50%;
  content: "";
  position: absolute;
  background: #d3d3d3;
  animation: preloader_after 1.8s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes preloader_before {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(160px) scale(1.5) rotate(264deg);
    background: rgb(1, 133, 199);
    border-radius: 0;
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes preloader_after {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(-160px) scale(1.5) rotate(-264deg);
    background: var(--white-color);
    border-radius: 0;
  }
  100% {
    transform: translateX(0px);
  }
}
.contact-form__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 50px;
  line-height: 0.91;
  color: #fff;
  margin-bottom: 23px;
}
.contact-form__subtitle {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.13;
  color: rgba(255, 255, 255, 0.8);
  max-width: 450px;
  margin-bottom: 23px;
}
.contact-form__input {
  border: none;
  border-radius: 10px;
  width: 100%;
  background: var(--white-color);
  padding: 28px 20px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  color: #1c1c1c;
  margin-bottom: 10px;
}
.contact-form__input::placeholder {
  font-family: inherit;
  color: rgba(28, 28, 28, 0.4);
  font-weight: inherit;
  font-size: inherit;
}
.contact-form__message {
  text-align: center;
}
.contact-form__policy {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.contact-form__link {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: #fff;
  text-decoration: underline;
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox + label {
  display: flex;
  gap: 20px;
  align-items: center;
  user-select: none;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: #fff;
}

.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #fff;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.mobile div {
  display: block;
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #3f8fd3;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  z-index: 9999999;
  border: 4px solid #9ad1ff;
  cursor: pointer;
  box-sizing: content-box;
}

.mobile div.active {
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.mobile div span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 2.7px;
  background-color: #fff;
  border-radius: 13px;
  transition-duration: 0.4s;
}

.mobile div span:nth-child(1) {
  top: 12px;
}

.mobile div span:nth-child(2) {
  top: 20px;
}

.mobile div span:nth-child(3) {
  bottom: 11px;
}

.mobile div.active span:nth-child(1) {
  transform: translate(-12.5px, 9px) rotate(-45deg);
  background-color: #000;
  width: 25px;
}

.mobile div.active span:nth-child(2) {
  transition-duration: 0s;
  opacity: 0;
}

.mobile div.active span:nth-child(3) {
  transform: translate(-12.5px, -7px) rotate(45deg);
  background-color: #000;
  width: 25px;
}

.mobile nav {
  display: block;
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999990;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mobile nav.open {
  background-color: var(--accent-color);
  width: 100%;
  height: 100%;
  right: 0px;
  bottom: 0px;
  border-radius: 0;
}

.mobile nav ul {
  display: none;
}

.mobile nav ul.show {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 2;
}

.mobile ul li:not(:last-child) {
  margin-bottom: 22px;
}

.mobile ul li a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mobile__nav {
  overflow: hidden;
  position: relative;
}
.mobile__line {
  position: absolute;
  z-index: 1;
}
.mobile__line--one {
  left: 277px;
  top: 67px;
  border: 1.31px solid rgba(255, 255, 255, 0.18);
  border-radius: 3918px;
  width: 711px;
  height: 711px;
}
.mobile__line--two {
  left: 252px;
  top: 42px;
  border: 1.4px solid rgba(255, 255, 255, 0.18);
  border-radius: 4193px;
  width: 761px;
  height: 761px;
}
.mobile__btn {
  position: relative;
  z-index: 2;
}