@media (max-width: 420px) {
  .reviews .reviews__info {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
}

@media (max-width: 500px) {
  .hero .hero__container .hero__title {
    font-size: clamp(1.5rem, 3.2vw, 3vh);
  }

  .hero .hero__container .hero__descr {
    font-size: clamp(0.8rem, 0.5vw, 5vh);
  }

  .hero .btn--car {
    font-size: 13px;
    padding: 4px 4px 4px 12px;
    gap: 10px;
  }

  .hero .hero__left {
    gap: 20px;
  }

  .hero .hero__content {
    padding: 25px 20px 0 20px;
  }

  .hero .hero__container {
    padding-top: calc(66px + 20px);
  }

  .section .container .section__title {
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 25px;
  }

  .card .card__text {
    font-size: 14px;
  }

  .cards .card {
    min-height: 230px;
  }

  .card .card__icon {
    width: 40px;
    height: 40px;
  }

  .reviews .reviews__pictures {
    width: 100%;
    padding: 0;
  }

  .reviews .reviews__img {
    width: 95%;
    height: 200px;
    margin: 0 auto;
    padding: 10px 0;
  }

  .section .reviews__info .section__title {
    font-size: 27px;
    margin: 0;
  }

  .reviews .reviews__text {
    font-size: 15px;
  }

  .section .job__content .section__title {
    font-size: 27px;
    margin: 0 0 15px 0;
    text-align: center;
  }

  .job .job__item {
    padding: 15px;
  }

  .job .job__text {
    max-width: 100%;
    font-size: 14px;
  }

  .job .job__content {
    padding: 25px 10px;
  }

  .job .btn--pick {
    font-size: 13px;
    padding: 4px 4px 4px 12px;
    gap: 10px;
  }

  .contact .contact__container .contact__img {
    width: 100% !important;
  }

  .contact-form .contact-form__title {
    font-size: clamp(1.9rem, 3.2vw, 3vh);
  }

  .contact-form .contact-form--content {
    padding: 30px 14px;
  }

  .contact-form .contact-form__subtitle {
    font-size: 14px;
  }

  .contact-form .contact-form__input {
    font-size: 14px;
    padding: 19px 12px;
  }

  .contact-form .custom-checkbox+label {
    font-size: 14px;
    gap: 8px;
  }

  .contact-form .contact-form__link {
    font-size: 14px;
  }

  .contact-form .custom-checkbox+label::before {
    width: 25px;
    height: 25px;
  }

  .contact-form .btn--submit {
    font-size: 13px;
    padding: 4px 4px 4px 12px;
    gap: 10px;
  }

  .video .video__container .video__slide .video__about {
    min-height: 450px;
  }

  #policy {
    padding-top: calc(80px + 20px) !important;
  }

  .policy .policy__container .policy__title {
    font-size: 20px;
  }

  .policy .policy__subtitle {
    font-size: 16px;
  }

  .policy .policy__text {
    font-size: 14px;
  }

  .reviews .reviews__about {
    height: 519px;
  }

  .reviews .reviews__content {
    height: 520px;
    max-height: 520px
  }

  .reviews .reviews__content--more {
    justify-content: space-around !important;
  }
}

@media (max-width: 768px) {
  #policy {
    padding-top: calc(100px + 30px);
  }

  .policy .policy__title {
    font-size: 25px;
  }

  .reviews .reviews__about {
    max-width: 100%;
  }

  .reviews .reviews__content--more {
    flex-direction: column !important;
  }

  .reviews .reviews__car--img {
    width: 83%;
    margin: 0 auto;
  }

  .section .section__title {
    font-size: 35px;
  }

  .cards .card {
    max-width: 100%;
    width: 100%;
  }

  .reviews .reviews__content {
    flex-direction: column-reverse;
  }

  .reviews .reviews__information {
    max-width: 100%;
    min-height: 240px;
  }

  .job .job__video {
    min-height: 450px;
  }

  .job .job__item {
    svg {
      display: none;
    }
  }

  .contact .contact__container .contact__img {
    width: 80%;
  }

  .job .job__video {
    display: none;
  }

  .video .video__info {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  .video h2 {
    margin-bottom: 0;
  }

  .footer .footer__left {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .footer .footer__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media (min-width:968px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 968px) {
  .header__content {
    display: none;
  }

  #cursor,
  #aura {
    display: none;
  }

  .hero .hero__car {
    position: relative;
    right: 0;
    top: 0;
  }

  .hero .hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero .hero__title {
    max-width: 100%;
    text-align: center;
  }

  .hero .hero__descr {
    max-width: 100% !important;
    text-align: center;
  }

  .hero .hero__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #hero {
    padding-bottom: 50px;
  }

  #advantages {
    padding-bottom: 50px;
  }

  #reviews {
    padding-bottom: 50px;
  }

  #contact {
    padding-bottom: 50px;
  }

  #video {
    padding-bottom: 50px;
  }

  .video .video__slide .video__about {
    min-height: 494px;
  }

  .footer .footer__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

@media (max-width: 1024px) {
  .header .social {
    margin-left: 0;
  }

  .video .video__img {
    width: 100%;
  }

  .video .video__about {
    max-width: 100%;
    min-height: 394px;
  }

  .video .video__car--img {
    width: 100%;
  }
}

@media (max-width: 1170px) {
  .hero .hero__title {
    font-size: clamp(2rem, 4.2vw, 5vh);
    max-width: 600px;
  }

  .hero .hero__descr {
    font-size: clamp(0.9rem, 0.5vw, 5vh);
    max-width: 400px;
  }

  .reviews .reviews__content {
    justify-content: center;
  }

  .job .job__blocks {
    flex-direction: column;
  }


  .job .job__video {

    video {
      left: 0;
    }
  }

  .contact .contact__content {
    flex-direction: column;
  }

  .contact .contact__img {
    height: 500px;
    margin: 0 auto;
  }

  .contact .contact__form {
    margin: 0 auto;
  }

  .video .video__slide {
    max-width: 100%;
  }


  .video .video__img {
    margin: 0 auto;
  }


}
