.footer {
  padding-bottom: 10px;

  &__content {
    border-radius: 20px;
    background: #1c1c1c;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    gap: 30px;
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.6);
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 400;
    text-decoration: underline;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.6);
    transition: .3s color ease-in-out;

    &:hover {
      color: #fff;
    }
  }

  &__item {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.6);
  }
}
