.reviews {
  padding-bottom: 100px;

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-top: 30px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__button {
    width: fit-content;
  }

  &__img {
    border-radius: 8px;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 12px;

    &--more {
      flex-direction: column;
      padding: 20px 14px 32px 22px;
      min-height: inherit;
      justify-content: space-between;
      position: relative;
      z-index: 2;
    }
  }

  &__about {
    background: #f8f8f8;
    border-radius: 10px;
    max-width: 281px;
    min-height: 337px;
    position: relative;
    overflow: hidden;
  }

  &__watch {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 137%;
    color: #1c1c1c;
  }

  &__more {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__information {
    background: #f8f8f8;
    border-radius: 10px;
    max-width: 281px;
    position: relative;
    overflow: hidden;
    min-height: 387px;

    &--content {
      padding: 20px;
    }
  }

  &__text {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 137%;
    color: #1c1c1c;
    position: relative;
    z-index: 2;
  }

  &__pictures {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 10px;
  }

  &__line {
    position: absolute;
    z-index: 1;

    &--one {
      left: -108px;
      bottom: 50px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 916px;
      height: 916px;
      transform: rotate(54deg);
    }

    &--two {
      left: -88px;
      bottom: 90px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 856px;
      height: 856px;
    }

    &--three {
      left: 240px;
      top: -184px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--four {
      left: 265px;
      top: -165px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--five {
      top: 72px;
      left: -123px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--six {
      top: 47px;
      left: -148px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--seven {
      top: 242px;
      right: 36px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }

    &--eight {
      top: 266px;
      right: 62px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--nine {
      top: 177px;
      right: -178px;
      border: 1.31px solid rgba(28, 28, 28, 0.05);
      border-radius: 3918px;
      width: 711px;
      height: 711px;
    }

    &--ten {
      top: 152px;
      right: -203px;
      border: 1.40px solid rgba(28, 28, 28, 0.05);
      border-radius: 4193px;
      width: 761px;
      height: 761px;
    }
  }
}
